import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Elastic from "../images/elasticsearch.png"
import HeroMini from "../components/heroSmall"

const ServicesPage = () => (
  <Layout>
    <Seo title="Web/Mobile" />
    <HeroMini title="Web/Mobile"/>
    <main className="section has-text-black">
  <div className="container">
  <div className="columns">
  <div className="column">
    <p className="mb-6">Depend on our full-spectrum of consultation services to provide the ingenuity you need to solve your challenges on time and within budget.</p>
  </div>
  </div>

  <div className="columns">
  <div className="column content has-text-centered">
  
    <p className="is-size-4 fusionfont">React Web Development</p>

    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/plasticine/100/000000/react.png"/>
    <p>React (also known as React.js or ReactJS) is an open-source, front end, JavaScript framework for building user interfaces or UI components. It is maintained by Facebook and a community of individual developers and companies.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">CMS</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/fluent/48/000000/management.png"/>
    <p>A content management system is a computer software used to manage the creation and modification of digital content. CMS’s are typically used for enterprise content management and web content management</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Web Apps</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/cotton/64/000000/web-apps.png"/>
    <p>Bespoke web application development</p>
  </div>
</div>
{/* 
<div className="columns">
  <div className="column content">
  <p className="is-size-2">React</p>
  </div>
  </div> */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Material UI</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/material-ui.png"/>
    <p>React UI components</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Gatsby</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios-glyphs/60/000000/today-apps.png"/>
    <p>GatsbyJS is an open-source framework based on ReactJS, Webpack, JavaScript and CSS that helps developers quickly build very fast static websites and apps.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">WordPress</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/wordpress.png"/>
    <p>WordPress is an open-source content management system.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">CSS</p>
  </div>
  </div>
 */}

<div className="columns">
  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">React native</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/nolan/64/react-native.png"/>
    <p>React Native is an open-source mobile application framework created by Facebook, Inc. It is used to develop applications for Android, Android TV, iOS, macOS, tvOS, Web, Windows and UWP</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Bulma</p>
    <img alt="" style={{maxWidth:"50px"}} src={Elastic}/>
    <p>A CSS framework is a library allowing for easier, more standards-compliant web design using the Cascading Style Sheets language. Most of these frameworks contain at least a grid. More functional frameworks also come with more features </p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sass</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/sass.png"/>
    <p>Sass is a pre-processor scripting language that is interpreted or compiled into Cascading Style Sheets.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">CMS</p>
  </div>
  </div> */}

<div className="columns">
<div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Api development</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/cotton/64/000000/api.png"/>
    <p>Application programming interface is an interface that defines interactions between multiple software applications or mixed hardware-software intermediaries.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Sanity CMS</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/ios/50/000000/search-bar.png"/>
    <p>Sanity Studio is an open-source headless real-time CMS, that you can customise with JavaScript and React. Efficient editing, instant UI for complex fields Responsive, works on small screens Plug-in architecture and custom input components.</p>
  </div>

  <div className="column content has-text-centered">
    <p className="is-size-4 fusionfont">Bootstrap</p>
    <img alt="" style={{maxWidth:"50px"}} src="https://img.icons8.com/color/48/000000/bootstrap.png"/>
    <p>Bootstrap is a free and open-source CSS framework directed at responsive, mobile-first front-end web development. It contains CSS- and JavaScript-based design templates for typography, forms, buttons, navigation, and other interface components.</p>
  </div>
</div>

{/* <div className="columns">
  <div className="column content">
  <p className="is-size-2">Azure</p>
  </div>
  </div> */}


</div>
</main>
  </Layout>
)

export default ServicesPage
