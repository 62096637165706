import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const HeroMini = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "test.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage fluid={imageData} backgroundColor={`#00a79d`} className="hero">
      <div className="hero-body">
        <div className="container">
          <h1 style={{color:"white"}} className="title content">
            {props.title}
          </h1>
        </div>
      </div>
    </BackgroundImage>
  )
}

// const StyledBackgroundSection = styled(BackgroundSection)`
//   width: 100%;
//   background-position: center left;
//   height:300px;
// `

export default HeroMini